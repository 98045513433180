export enum Routes {
  AdminFeatureFlags = '/admin/feature-flags',
  AdminDevices = '/admin/supply-tenants',
  TenantRequests = '/admin/tenant-requests',

  Admin = '/admin',
  AdminOrganization = '/admin/organizations/$organizationId',
  AdminOrganizationEdit = '/admin/organizations/$organizationId/edit',
  AdminUser = '/admin/users/$userId',
  AdminUserEdit = '/admin/users/$userId/edit',
  AdminDeviceDecom = '/admin/device-decom',

  AdminDevicesSupplyTenant = '/admin/supply-tenants/$organizationId',
  AdminDevicesSupplyTenantDevice = '/admin/supply-tenants/$organizationId/devices/$deviceId',
  AdminDevicesDeviceLogs = '/admin/devices/$deviceId/logs',
  AdminUsersOrgsSearch = '/admin/users-orgs/search',
  AdminUsersOrgsManage = '/admin/users-orgs/manage',
  AdminReservations = '/admin/reservations',
  AdminDeleteOrganization = '/admin/users-orgs/manage/organization/$organizationId/delete',
  AdminMarkOrganizationTrusted = '/admin/users-orgs/manage/organization/$organizationId/confirm',

  Unauthorized = '/unauthorized',
  NotFound = '/notfound',

  HelpdeskNewTicket = '/helpdesk/new-ticket',
  HelpdeskTickets = '/helpdesk/tickets',
  HelpdeskTicket = '/helpdesk/tickets/$ticketId',
  DcimAccounts = '/dcim/accounts',
  DcimAccountsAccountDocuments = '/dcim/accounts/$organizationId/documents',
  DcimAccountsAccountManage = '/dcim/accounts/$organizationId/manage',
  DcimAccountsAccountOnboarding = '/dcim/accounts/$organizationId/onboarding',
  DcimAccountsAccountOnboardingPrerequisites = '/dcim/accounts/$organizationId/onboarding/prerequisites',
  DcimAccountsAccountPayouts = '/dcim/accounts/$organizationId/payouts',

  DcimDevices = '/dcim/devices',
  DcimDevicesDeviceNetworking = '/dcim/$organizationId/devices/$deviceId/networking',
  DcimDevicesDeviceNetworkingEdit = '/dcim/$organizationId/devices/$deviceId/networking/edit',
  DcimDevicesDeviceOverview = '/dcim/$organizationId/devices/$deviceId/overview',
  DcimDevicesDeviceProvision = '/dcim/$organizationId/devices/$deviceId/provision',
  DcimDevicesDeviceProvisionAddSshKey = '/dcim/$organizationId/devices/$deviceId/provision/add-sshkey',
  DcimDevicesDevicesSettings = '/dcim/$organizationId/devices/$deviceId/settings',
  DcimStorefronts = '/dcim/storefronts',

  Deployments = '/deployments',
  DeploymentsDeviceConnect = '/deployments/$deviceId/connect',
  DeploymentsDeviceOverview = '/deployments/$deviceId/overview',
  DeploymentsDeviceReprovision = '/deployments/$deviceId/reprovision',
  DeploymentsDeviceReboot = '/deployments/$deviceId/reboot',
  DeploymentsDeviceRebootConfirmation = '/deployments/$deviceId/reboot/confirmation',
  DeploymentsDeviceReprovisionAddSshKey = '/deployments/$deviceId/reprovision/add-sshkey',
  DeploymentsDeviceReprovisionConfirmation = '/deployments/$deviceId/reprovision/confirmation',
  DeploymentsDeviceSettings = '/deployments/$deviceId/settings',

  Error = '/error',

  Login = '/login',
  Logout = '/logout',
  SignUp = '/signup',

  Marketplace = '/marketplace/category',
  MarketplaceCategory = '/marketplace/category/$category',
  MarketplaceDevice = '/marketplace/$deviceId',
  MarketplaceDeviceCheckout = '/marketplace/$deviceId/checkout',

  OnboardingEmailVerification = '/onboarding/email-verification',
  OnboardingOrganizationMembership = '/onboarding/organization-membership',

  OrganizationsApiKeys = '/organizations/$organizationId/api-keys',
  OrganizationsApiKeysCreate = '/organizations/$organizationId/api-keys/create',
  OrganizationsApiKeysDelete = '/organizations/$organizationId/api-keys/$apiKeyId/delete',
  OrganizationsBilling = '/organizations/$organizationId/billing',
  OrganizationsBillingPaymentMethodAdd = '/organizations/$organizationId/billing/payment-methods/add',
  OrganizationsBillingPaymentMethodDefault = '/organizations/$organizationId/billing/payment-methods/$paymentMethodId/default',
  OrganizationsCreate = '/organizations/create',
  OrganizationsMembers = '/organizations/$organizationId/members',
  OrganizationsSitesZones = '/organizations/$organizationId/sites-and-zones',
  OrganizationsSettings = '/organizations/$organizationId/settings',

  PublicMarketplace = '/public/marketplace/category',
  PublicMarketplaceCategory = '/public/marketplace/category/$category',
  PublicMarketplaceRequestDevice = '/public/marketplace/requestdevice/$searchValue',

  ResourceValidateEmail = '/resource/validate-email',

  SettingsSshKeys = '/settings/sshkeys',
  SettingsSshKeysCreate = '/settings/sshkeys/create',
  SettingsSshKeysDelete = '/settings/sshkeys/$sshKeyId/delete',

  SettingsUser = '/settings/user',
  SettingsUserEdit = '/settings/user/edit',

  StoresSlug = '/stores/$slug',
  StoresSlugItem = '/stores/$slug/$itemId',
}

type RouteParams = {
  [key: string]: string | number | undefined;
};

export function withParams(
  route: Routes | string,
  params: RouteParams,
): string {
  let result = route;

  for (const [key, value] of Object.entries(params)) {
    result = result.replace(`$${key}`, value?.toString() || '');
  }

  return result;
}
